(function($, window, document, undefined) {

  'use strict';

  $(function() {



    var voirPopOut = $('.voirPopOut'),
        fermerPopOut = $('.fermerPopOut');

    voirPopOut.on('click', function(event) {
      event.preventDefault();
      $(this).addClass('goVerlay');
      $('nav .boutons').addClass('overlayOpened');
      $('.sidebar').addClass('showSidebar');
      $('body').addClass('overlayed');
    });

    fermerPopOut.on('click', function(event) {
      event.preventDefault();
      $('.goVerlay').removeClass('goVerlay');
      $('nav .boutons').addClass('overlayOpened');
      $('.sidebar').removeClass('showSidebar');
      $('body').removeClass('overlayed');
    });






    //var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; 
    //var iw = (iOS) ? screen.width : window.innerWidth, ih = (iOS) ? screen.height : window.innerHeight;
    //console.log(innerHeight());
    var headersMenu = $('.headerMenu');

    headersMenu.on('click', function(event) {
      event.preventDefault();
      var that = $(this),
        thisLevel1 = that.parent('.inner').parent('.level1'),
        thisitemsMenu = that.siblings('.itemsMenu'),
        thisitemsMenuLength = thisitemsMenu.children('.lvl2').length;

      /*thisLevel1
        .toggleClass('activeLevel1')
        .siblings('.level1')
        .removeClass('activeLevel1');*/

      if (thisLevel1.is('.activeLevel1')) {
        thisitemsMenu.height(0)
          .parent('.inner').parent('.level1').removeClass('activeLevel1');
      } else {

        thisitemsMenu.css('height', 80 * thisitemsMenuLength + 'px')
          .parent('.inner').parent('.level1').addClass('activeLevel1')
          .siblings('.level1.activeLevel1').removeClass('activeLevel1')
          .find('.itemsMenu').css('height', 0 + 'px');

        function goScroll() {
          var page = thisLevel1; // Page cible
          var speed = 750; // Durée de l'animation (en ms)
          console.log(thisLevel1.offset().top);
          $('html, body').animate({ scrollTop: thisLevel1.offset().top }, 300);
        }
        //setTimeout(goScroll, 300);
      }

    });

    










    var scene = $('#scene'),
      viewport = $('#viewport'),
      introSection = $('#introSection');




    function setScene() {
      var tl = new TimelineMax({ id: "setScene" });
      tl.from(viewport, 30, { z: 200, transformOrigin: 'center bottom', ease: Power3.easeOut })
      return tl;
    }
    var sky = $('#skyScene'),
      sun = $('#sun'),
      sunPix = $('#sun>img');
    //nuage_1 = $('#nuage_1')
    //nuage_2 = $('#nuage_2');

    var logo_wrapper = $('#logo_wrapper'),
      logo = $('#logo'),
      logoPath = $('#logo path'),
      slogan_wrapper = $('.slogan'),
      slogan = $('#slogan'),
      sloganSplit = new SplitText(slogan, { type: "chars" });
    var logoDrawDuration = 1;

    function setLogo() {
      var tl = new TimelineMax({ id: "setLogo" });
      tl
        .to(logo_wrapper, 1.3, { autoAlpha: 1, ease: Power3.easeOut }, 0)
        .staggerFromTo(logoPath, 1.3, { strokeOpacity: 1, drawSVG: "50% 50%" }, { drawSVG: "0% 100%", immediateRender: false, ease: Power3.easeInOut }, 0.25, 0)
        .staggerTo(logoPath, 0.4, { fillOpacity: 1, strokeOpacity: 0, ease: Power3.easeOut }, 0.25, 0.7)
        .staggerFrom(sloganSplit.chars, 0.7, { opacity: 0, rotation: -180, y: -100, ease: Back.easeOut }, 0.07, 0.8);
      return tl;
    }

    function setSky() {
      var tl = new TimelineMax({ id: "setSky" });
      tl
        .to(sun, 3, { y: '-40%', ease: Elastic.easeInOut.config(0.75, 0.3) });
      //.to(nuage_1, 2, { autoAlpha:1, y:'30em', ease: Elastic.easeInOut.config(0.75, 0.3) },1.3);
      //.to(sun, 10, { rotate:'360deg', ease: Power3.easeInOut})
      return tl;
    }

    function sunTourne() {
      var tl = new TimelineMax({ id: "sunTourne" });
      tl
        .to(sun, 100, { rotation: 360, transformOrigin: 'center center', ease: Power0.easeNone, repeat: -1 });
      return tl;
    }

    /*function nuageBouge() {
    var tl = new TimelineMax({id:"nuageBouge"});
    tl
      .fromTo(nuage_1, 10,{x:'-50%'}, {x:"150%", ease: Power3.easeOut, repeat:-1, yoyo:true});
      //.fromTo(nuage_2, 100,{right:'-50%'}, {right:"150%", ease: Power3.easeOut, repeat:-1, yoyo:true});
    return tl;
  }*/

    //TweenMax.to(sun, 3, { rotate:360, ease: Power0.none , repeat: -1});

    //TweenLite.to(sunPix, 10, {rotate:'360deg', ease: Power3.easeInOut});

    var immeubleScene1 = $('#immeubleScene1 > .immeubles_wrapper'),
      immeubleScene2 = $('#immeubleScene2 > .immeubles_wrapper'),
      immeubleScene3 = $('#immeubleScene3 > .immeubles_wrapper'),
      logoScene = $('#logoScene'),
      chappelleScene = $('#chappelleScene > #chappelle_wrapper');

    var chappelle = $('#chapelle'),
      bgScene = $('#bgScene'),
      immeubles = $('.immeubles'),
      immeubles_duration = 3;

    function setImmeubles() {
      var tl = new TimelineMax({ id: "setImmeubles" });
      tl
        .to(immeubleScene1, immeubles_duration, { scaleY: 1, ease: Elastic.easeInOut }, 0)
        .to(immeubleScene2, immeubles_duration, { scaleY: 1.3, ease: Elastic.easeInOut }, 0.1)
        .to(immeubleScene3, (immeubles_duration + 0.05), { scaleY: 1.1, ease: Elastic.easeInOut }, 0.2)
        .to(chappelleScene, (immeubles_duration - 1), { scaleY: 1, y: '-20em', ease: Elastic.easeOut.config(0.75, 0.3) }, 1.85);
      //.to([immeubleScene1,immeubleScene2,immeubleScene3,chappelleScene], 2,  { scale:"+=0.1",  ease: Power1.easeInOut})
      return tl;
    }

    var nav = $('.nav');

    function setNav() {
      var tl = new TimelineMax({ id: "setNav" });
      tl
        .to(nav, 0.3, { autoAlpha: 1, ease: Power0.easeNone }, 0)
      return tl;
    }


    var master = new TimelineMax({
      //timeScale: 2
    });
    master
      .add(setImmeubles(), 0)
      .add(setSky(), "-=3.05")
      .add(setNav(), "-=0.2")
      .add(setLogo(), "-=0.6")
      .timeScale(2)
    //.add(sunTourne(), "setSky-=2.85")
    //.add(nuageBouge(), "setSky")
    //.add(TweenMax.to(sunPix, 3, { rotate:360, ease: Power0.none}))
    //.add(setScene(),4)
    //.progress(1)
    ;

    //GSDevTools.create({visibility:'auto',css:{top:'1%'}});



  $(document).ready(function() {
// Init ScrollMagic
    var controller = new ScrollMagic.Controller();

    //pin the intro
/*    var pinIntroScene = new ScrollMagic.Scene({
      triggerElement:'#introSection',
      triggerHook: 0
    })
    .setPin('#introSection')
    .addTo(controller);*/
    //.setTween(TweenMax.to(viewport, 2, { z: 800, transformOrigin: 'center bottom', ease: Power3.easeOut }))

    var persotl = new TimelineMax()
      .to('.persoBlock',0.5, { y:'-20%'})
      .to('.personnageShadow',0.5, { x:'-10%',rotation:-10,scaleY:1.1,opacity:0.1,transformOrigin:'30% 40%'},0);

    var scrollIntro = new ScrollMagic.Scene({
      triggerElement: '#infoSection',
      offset:-300,
      duration:'100%'
    })
    .setTween(persotl)
    //.addIndicators({name:'introSection'})
    .addTo(controller);


    /*$('.nav').height($(document).height());
    $(window).resize(function(event) {
      $('.nav').height($(document).height());
    });*/
  });


  });



})(jQuery, window, document);
